module.exports = {
	id: 'TC',
	lang: '中文',
	language: '语言',
	languageConfirm: '确定切换语言吗?',
	more: '查看更多',
	play: '播放',
	detail: '详情',
	balance: '余额',
	amount: '金额',
	cny: '元',
	bet: '注',
	ci: '次',
	all: '全部',
	input: '请输入',
	inputFill: '请填写完整',
	select: '请选择',
	choice: '选择',
	confirm: '確定',
	cancel: '取消',
	save: '保存',
	submit: '提交',
	reset: '重置',
	recharge: '充值',
	withdrawal: '提现',
	nomore: '没有更多了',
	none: '暂无数据',
	logout: '退出登录',
	success: '操作成功',
	fail: '操作失败',
	noSet: '未设置',
	hasSet: '已设置',
	needRecharge: '请充值',
	refreshSucceeded: '刷新成功',
	login: {
		title: '登录',
		user: '用户名',
		password: '密码',
		forgot: '忘记密码?',
		toreg: '没有账户？马上注册',
		submit: '登录',
	},
	register: {
		title: '注册',
		user: '请输入用户名(6-12个英文字母或数字)',
		password: '请输入登录密码(6-12个英文字母或数字)',
		code: '请输入邀请码',
		policy: '我已经知晓并同意"开户协议"各项条约',
		policyTips: '请勾选下方开户协议！',
		submit: '注册',
	},
	menu: {
		index: '首页',
		game: '预约',
		video: '视频',
		user: '我的'
	},
	index: {
		hotLottery: '热门彩票',
		eliteTask: '推荐任务',
		peopleRanking: '人气排行',
		hotElite: '热门推荐',
	},
	game: {
		title: '预约大厅',
		shuang: '双',
		dan: '单',
		da: '大',
		xiao: '小',
		bettingAmount: '下注金额',
		orderTime: '下单时间',
		settlementTime: '结算时间',
	},
	video: {
		title: '视频影院',
		hotElite: '热门推荐',
	},
	xuanfei: {
		title: '选妃',
		yue: '预约',
		yueTips: '请联系顾问或接待员',
		city: '城市大全',
		processPrice: '流程价格',
		tips: '本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。',
		tips1: '有哪些资源?',
		tips2: '网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到',
		tips3: '服务范围?',
		tips4: '同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。',
	},
	task: {
		title: '任务记录',
		taskList: '任务单',
		receiveTask: '请联系管理员领取该任务',
	},
	lottery: {
		rule: '玩法规则',
		tipsTitle1: '玩法提示',
		tipsContent1: '从可选和值形态里面选择号码进行下注',
		tipsTitle2: '中奖说明',
		tipsContent2: '三个开奖号码总和值11~18 为大;总和值3~ 10为小',
		tipsTitle3: '投注范例',
		tipsContent3: '投注方案：小 开奖号码：123,即中小',
		balance: '可用余额',
		currentNumber: '当前选号',
		eachAmount: '每注金额',
		total: '总共',
		totalAmount: '合计',
		phase: '期号',
		lotteryNumber: '开奖号码',
		selectNumber: '选号',
		unSelected: '未选择',
		unChecked: '未选中',
		prizeSuccess: '开奖成功',
	},
	user: {
		title: ' ',
		fun1: '我的钱包',
		fun2: '个人报表',
		fun3: '账户明细',
		fun4: '任务记录',
		fun5: '个人中心',
		fun6: '信息公告',
		fun7: '在线客服',
		completeTask: '请完成任务单后进入',
		serviceRecharge: '请联系客服充值',
		collectionCard: '请设置收款卡',
		featureDisabled: '功能已禁用',
		accountOffline: '账号下线',
		loginReg: '登录/注册',
		loginRegTips: '登录可享受更多服务',
	},
	userBase: {
		title: '基本信息',
		avatar: '头像',
		name: '真实姓名',
		nameTips: '为了您账户安全,真实姓名需要与绑定银行卡姓名一致',
		sex: '性别',
		sex1: '男',
		sex2: '女',
		sex3: '未知',
		bind: '绑定信息',
		hasBind: '已绑定',
		oldPwd: '旧密码',
		newPwd: '新密码',
		reNewPwd: '重复新密码',
		atypism: '两次输入不一致',
	},
	userSet: {
		title1: '修改真实姓名',
		title2: '修改真实姓名',
		title3: '收款卡信息',
		title4: '填写收款卡',
		title5: '信息公告',
		title6: '修改登录密码',
		title7: '设置资金密码',
	},
	userReport: {
		title: '个人报表',
		profitAmount: '盈利金额',
		profitAmountTips: '盈利计算公式 : 中奖金额 - 任务金额',
		taskAmount: '任务金额',
		rechargeAmount: '充值金额',
		withdrawalAmount: '提现金额',
		winningAmount: '中奖金额',
	},
	bindCard: {
		addCard: '添加收款卡',
		addCardTips: '提示:请绑定大型商业银行,如需修改,请您联系在线客服',
		addCardTips1: '请设置姓名后再绑定银行卡',
		addCardTips2: '请设置提现密码后再绑定银行卡',
		collectionCardTips1: '请输入您的收款卡信息',
		collectionCardTips2: '尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款,',
		cardNo: '银行卡号',
		bankName: '银行名称',
	},
	withdraw: {
		title: '提现中心',
		record: '提现记录',
		amount: '提现金额',
		limitTips: '限额说明',
		singleLimit: '单笔限额',
		minimum: '最低',
		highest: '最高',
		amount: '金额',
		times: '提现次数',
		timesTips: '一天最高提现',
		receiptDate: '到账时间：一般到账时间在5分钟左右，最快2分钟内到账',
		explain: '说明',
		submitTime: '提交时间',
		auditTime: '审核时间',
	},
	set: {
		title: '设置',
		baseInfo: '基本信息设置',
		loginPwd: '登录密码',
		capitalPwd: '资金密码',
		reCapitalPwd: '资金密码',
		withdrawalTips: '提现密码已设置，需要修改请联系客服',
	},
	online: {
		title: '在线客服',
		contact: '联系',
		contactTips: '全天7 * 24小时竭诚为您服务',
	},
}
