export default {
	isLogin: function(_this) {
		if (!_this.$store.getters.getLoginValue) {
			return _this.$router.push("Login")
		}
	},
	getLang() {
		let lang = localStorage.getItem('sjc_app_lang') || 'tha'
		return lang
	},
	getLangContent(item, field) {
		let lang = this.getLang()
		return item[field + '_' + lang] ? item[field + '_' + lang] : item[field]
	},
}
