module.exports = {
	id: 'THA',
	lang: 'ภาษาไทย',
	language: 'ภาษา',
	languageConfirm: 'คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนภาษา?',
	more: 'ดูเพิ่มเติม',
	play: 'เล่น',
	detail: 'รายละเอียด',
	balance: 'รีเซ็ตยอดเงิน',
	amount: 'จำนวนเงิน',
	cny: 'บาท',
	bet: 'หมายเหตุ',
	ci: 'ครั้ง',
	all: 'ทั้งหมด',
	input: 'โปรดป้อน',
	inputFill: 'กรุณากรอกข้อมูลให้ครบถ้วน',
	select: 'โปรดเลือก',
	choice: 'เลือก',
	confirm: 'ยืนยัน',
	cancel: 'ยกเลิก',
	save: 'บันทึก',
	submit: 'ส่ง',
	reset: 'รีเซ็ต',
	recharge: 'เติมเงิน',
	withdrawal: 'ถอนเงิน',
	nomore: 'ไม่มาก',
	none: 'ไม่มีข้อมูลชั่วคราว',
	logout: 'ออกจากระบบ',
	success: 'การดำเนินงานที่ประสบความสำเร็จ',
	fail: 'ปฏิบัติการล้มเหลว',
	noSet: 'ยังไม่ได้ตั้งค่า',
	hasSet: 'ตั้งค่า',
	needRecharge: 'กรุณาเติมเงิน',
	refreshSucceeded: 'รีเฟรชความสำเร็จ',
	login: {
		title: 'ล็อกอิน',
		user: 'ชื่อผู้ใช้',
		password: 'รหัสผ่าน',
		forgot: 'ลืมรหัสผ่าน?',
		toreg: 'ยังไม่มีบัญชี？ลงทะเบียนตอนนี้',
		submit: 'เข้าสู่ระบบ',
	},
	register: {
		title: 'ลงทะเบียน',
		user: 'โปรดป้อนชื่อผู้ใช้ (6-12 ตัวอักษรภาษาอังกฤษหรือตัวเลข)',
		password: 'โปรดป้อนรหัสผ่านเข้าสู่ระบบ(6-12ตัวอักษรภาษาอังกฤษหรือตัวเลข)',
		code: 'โปรดป้อนรหัสเชิญ',
		policy: 'ฉันรู้และเห็นด้วย"ข้อตกลงการเปิดบัญชี"สนธิสัญญาต่างๆ',
		policyTips: 'โปรดระบุข้อตกลงในการเปิดบัญชีด้านล่าง！',
		submit: 'ลงทะเบียน',
	},
	menu: {
		index: 'หน้าหลัก',
		game: 'กิจกรรม',
		video: 'วิดีโอ',
		user: 'ของฉัน'
	},
	index: {
		hotLottery: 'กิจกรรมยอดนิยม',
		eliteTask: 'กิจกรรมแนะนำ',
		peopleRanking: 'คะแนนนิยม',
		hotElite: 'คำแนะนำยอดนิยม',
	},
	game: {
		title: 'ห้องกิจกรรม',
		shuang: 'ดับเบิ้ล',
		dan: 'โสด',
		da: 'ใหญ่',
		xiao: 'เล็ก',
		bettingAmount: 'จำนวนเงินเดิมพัน',
		orderTime: 'เวลาสั่งซื้อ',
		settlementTime: 'เวลาการชำระบัญชี',
	},
	video: {
		title: 'ห้องบันเทิง',
		hotElite: 'คำแนะนำยอดนิยม',
	},
	xuanfei: {
		title: 'สาวสวย',
		yue: 'นัดหมาย',
		yueTips: 'โปรดติดต่อที่ปรึกษาหรือพนักงานต้อนรับ',
		city: 'แหล่งรวม',
		processPrice: 'ราคากระบวนการ',
		tips: 'แพลตฟอร์มนี้ทั้งเครือข่ายรอบนอกที่ สมจริงที่สุด+เพื่อทางธุรกิจ+ความหลงใหลในเรื่องเดียวกัน เพื่อรับประกันความเป็นส่วนตัวของผู้ใช้ แต่ละรายสมาชิกสามารถเข้าร่วมได้โดยติดต่อพนักงานต้อนรับหรือแพลตฟอร์มการแนะนำชื่อจริงของสมาชิกที่มีอายุ 18 ปีขี้นไปเท่านั้น',
		tips1: 'มีทรัพยากรอะไรบ้าง?',
		tips2: 'เน็ตไอดอล, นางแบบ, แอร์โฮสเตส, นางแบบ, นักศึกษาวิทยาลัย, เพียงคุณคิดไม่ถึง, ไม่มีแพลตฟอร์มนี้ไม่สามารถทำ',
		tips3: 'ขอบเขตการให้บริการ?',
		tips4: 'เมืองเดียวกันฟรีนัดปืน, สถานที่ใด ๆ ทั่วประเทศทางอากาศ, ประเทศชั้นหนึ่งและสองเมืองในท้องถิ่น, เมืองชั้นสามยังสามารถจัดโดยการติดต่อพนักงานต้อนรับสำหรับการนัดหมาย',
	},
	task: {
		title: 'ประวัติการเล่น',
		taskList: 'รายการงาน',
		receiveTask: 'กรุณาติดต่อผู้ดูแลระบบเพื่อรับงาน',
	},
	lottery: {
		rule: 'กฎการเล่น',
		tipsTitle1: 'เคล็ดลับการเล่น',
		tipsContent1: 'เลือกหมายเลขจากรูปแบบตัวเลือกและมูลค่าสำหรับการเดิมพัน',
		tipsTitle2: 'คำแนะนำการชนะ',
		tipsContent2: 'ผลรวมของหมายเลขผลรวมสามตัวมีขนาดใหญ่ 11 ~ 18 มูลค่ารวม 3 ~ 10 มีขนาดเล็ก',
		tipsTitle3: 'กระบวนทัศน์การเดิมพัน',
		tipsContent3: 'แผนการพนัน: หมายเลขการออกรางวัลขนาดเล็ก: 123, นั่นคือขนาดกลางและขนาดเล็ก',
		balance: 'จำนวนเงิน',
		currentNumber: 'หมายเลขที่เลือกไว้',
		eachAmount: 'จำนวนเงินต่อเดิมพัน',
		total: 'ทั้งหมด',
		totalAmount: 'รวม',
		phase: 'หมายเลขภาค',
		lotteryNumber: 'หมายเลขที่ออก',
		selectNumber: 'เลือกหมายเลข',
		unSelected: 'ไม่เลือก',
		unChecked: 'ไม่ถูกเลือก',
		prizeSuccess: 'ความสำเร็จในการออก',
	},
	user: {
		title: ' ',
		fun1: 'กระเป๋าของฉัน',
		fun2: 'รายงานส่วนตัว',
		fun3: 'รายละเอียดบัญชี',
		fun4: 'ประวัติการเล่น',
		fun5: 'ศูนย์ส่วนบุคคล',
		fun6: 'ประกาศ',
		fun7: 'บริการลูกค้าออนไลน์',
		completeTask: 'โปรดป้อนหลังจากเสร็จสิ้นการสั่งซื้องาน',
		serviceRecharge: 'กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อเติมเงิน',
		collectionCard: 'กรุณาตั้งค่าบัตรใบเสร็จรับเงิน',
		featureDisabled: 'ฟังก์ชั่นถูกปิดใช้งาน',
		accountOffline: 'บัญชีออฟไลน์',
		loginReg: 'เข้าสู่ระบบ ลงทะเบียน',
		loginRegTips: 'เข้าสู่ระบบสามารถเพลิดเพลินกับบริการเพิ่มเติม',
	},
	userBase: {
		title: 'ข้อมูลพื้นฐาน',
		avatar: 'รูปโปรไฟล์',
		name: 'ชื่อจริง',
		nameTips: 'ชื่อจริงต้องตรงกับชื่อบัตรธนาคารที่ผูกเพื่อความปลอดภัยของบัญชีของคุณ',
		sex: 'เพศ',
		sex1: 'ชาย',
		sex2: 'ผู้หญิง',
		sex3: 'ไม่ทราบ',
		bind: 'ผูกบัญชี',
		hasBind: 'ผูกพัน',
		oldPwd: 'รหัสผ่านเก่า',
		newPwd: 'รหัสผ่านใหม่',
		reNewPwd: 'ทำซ้ำรหัสผ่านใหม่',
		atypism: 'การป้อนข้อมูลสองครั้งไม่สอดคล้องกัน',
	},
	userSet: {
		title1: 'แก้ไขชื่อจริง',
		title2: 'แก้ไขชื่อจริง',
		title3: 'ข้อมูลบัตรรับเงิน',
		title4: 'กรอกบัตรรับเงิน',
		title5: 'ประกาศ',
		title6: 'แก้ไขรหัสผ่านเข้าสู่ระบบ',
		title7: 'ตั้งรหัสผ่านกองทุน',
	},
	userReport: {
		title: 'รายงานส่วนบุคคล',
		profitAmount: 'จำนวนเงินที่ทำกำไร',
		profitAmountTips: 'สูตรการคำนวณกำไร: จำนวนเงินที่ชนะ - จำนวนงาน',
		taskAmount: 'จำนวนงาน',
		rechargeAmount: 'จำนวนเงินที่เติมเงิน',
		withdrawalAmount: 'จำนวนเงินที่ถอน',
		winningAmount: 'จำนวนเงินที่ชนะ',
	},
	bindCard: {
		addCard: 'เพิ่มบัตรรับเงิน',
		addCardTips: 'Tips: กรุณาผูกกับธนาคารพาณิชย์ขนาดใหญ่ หากต้องการแก้ไขกรุณาติดต่อฝ่ายบริการลูกค้าออนไลน์',
		addCardTips1: 'กรุณาตั้งชื่อก่อนผูกบัตรธนาคาร',
		addCardTips2: 'กรุณาตั้งรหัสผ่านถอนเงินก่อนผูกบัตรธนาคาร',
		collectionCardTips1: 'โปรดป้อนข้อมูลบัตรรับเงินของคุณ',
		collectionCardTips2: 'ท่านที่เคารพ, เพื่อความปลอดภัยของเงินของคุณ, โปรดผูกชื่อจริงของคุณและตั้งรหัสผ่านสำหรับการถอนเงิน, หากชื่อไม่ตรงกับชื่อเปิดบัญชี, จะไม่สามารถถอน,',
		cardNo: 'หมายเลขบัญชี',
		bankName: 'เลือกบัญชีธนาคาร',
	},
	withdraw: {
		title: 'ศูนย์ถอนเงินสด',
		record: 'บันทึกเงินสด',
		amount: 'จำนวนเงินถอน',
		limitTips: 'คำอธิบายขีด จำกัด',
		singleLimit: 'วงเงินครั้งเดียว',
		minimum: 'ต่ำสุด',
		highest: 'สูงสุด',
		amount: 'จำนวนเงิน',
		times: 'ถอนครั้ง',
		timesTips: 'ถอนเงินสดสูงสุดในหนึ่งวัน',
		receiptDate: 'เวลาเข้าบัญชี: โดยทั่วไปเวลาเข้าบัญชีจะอยู่ที่ประมาณ 5 นาทีเร็วที่สุด 2 นาทีเข้าบัญชี',
		explain: 'คำแนะนำ',
		submitTime: 'ส่งเวลา',
		auditTime: 'เวลาการตรวจสอบ',
	},
	set: {
		title: 'การตั้งค่า',
		baseInfo: 'การตั้งค่าข้อมูลพื้นฐาน',
		loginPwd: 'รหัสผ่านเข้าสู่ระบบ',
		capitalPwd: 'รัหสถอนเงิน',
		reCapitalPwd: 'รัหสถอนเงิน',
		withdrawalTips: 'รหัสผ่านถอนเงินสดถูกตั้งค่าแล้วและต้องแก้ไขโปรดติดต่อฝ่ายบริการลูกค้า',
	},
	online: {
		title: 'บริการลูกค้าออนไลน์',
		contact: 'ติดต่อ',
		ContactTips: 'ตลอด 7 วัน * 24 ชั่วโมงให้บริการคุณอย่างจริงใจ',
	},
}
