import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
	locale: localStorage.getItem('sjc_app_lang') || 'tha',
	messages: {
		'tc': require('./common/locale/tc'),
		'tha': require('./common/locale/tha')
	}
})

import 'video.js/dist/video-js.css'



Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
